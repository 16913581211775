<template>
  <product title="Amazfit 羚羊轻户外跑鞋"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="249"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    :video="video"
    buy-link="https://j.youzan.com/TlWkKh"
  ></product>
</template>

<script>
import { CDN_URL } from '@/config'
import Product from '../Product'

export default {
  name: 'antelope',
  components: {
    Product
  },
  data () {
    return {
      slogan: '减震高弹 | 防滑耐磨 | 舒适透气 | 防泼水',
      colors: [
        '黑色(男)',
        '浅灰色',
        '活力橙',
        '黑色(女)',
        '蔷薇粉',
        '薄荷绿'
      ],
      video: {
        url: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/videos/antelope.mp4',
        poster: CDN_URL + '/images/poster/antelope.png'
      },
      swiperData: [
        [
          CDN_URL + '/images/product/antelope/swiper/9.png',
          CDN_URL + '/images/product/antelope/swiper/10.png',
          CDN_URL + '/images/product/antelope/swiper/17.png'
        ],
        [
          CDN_URL + '/images/product/antelope/swiper/3.png',
          CDN_URL + '/images/product/antelope/swiper/4.png',
          CDN_URL + '/images/product/antelope/swiper/14.png'
        ],
        [
          CDN_URL + '/images/product/antelope/swiper/1.png',
          CDN_URL + '/images/product/antelope/swiper/2.png',
          CDN_URL + '/images/product/antelope/swiper/13.png'
        ],
        [
          CDN_URL + '/images/product/antelope/swiper/11.png',
          CDN_URL + '/images/product/antelope/swiper/12.png',
          CDN_URL + '/images/product/antelope/swiper/18.png'
        ],
        [
          CDN_URL + '/images/product/antelope/swiper/5.png',
          CDN_URL + '/images/product/antelope/swiper/6.png',
          CDN_URL + '/images/product/antelope/swiper/15.png'
        ],
        [
          CDN_URL + '/images/product/antelope/swiper/7.png',
          CDN_URL + '/images/product/antelope/swiper/8.png',
          CDN_URL + '/images/product/antelope/swiper/16.png'
        ]
      ],
      relativedProducts: [
      ],
      detailImages: [
        CDN_URL + '/images/product/antelope/detail/01.jpg',
        CDN_URL + '/images/product/antelope/detail/02.jpg',
        CDN_URL + '/images/product/antelope/detail/03.jpg',
        CDN_URL + '/images/product/antelope/detail/04.jpg',
        CDN_URL + '/images/product/antelope/detail/05.jpg',
        CDN_URL + '/images/product/antelope/detail/06.jpg',
        CDN_URL + '/images/product/antelope/detail/07.jpg',
        CDN_URL + '/images/product/antelope/detail/08.jpg',
        CDN_URL + '/images/product/antelope/detail/09.jpg',
        CDN_URL + '/images/product/antelope/detail/10.jpg',
        CDN_URL + '/images/product/antelope/detail/11.jpg',
        CDN_URL + '/images/product/antelope/detail/12.jpg',
        CDN_URL + '/images/product/antelope/detail/13.jpg',
        CDN_URL + '/images/product/antelope/detail/14.jpg',
        CDN_URL + '/images/product/antelope/detail/15.jpg'
      ],
      paramImages: [
        CDN_URL + '/images/product/antelope/detail/16.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
